import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Divider, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ChargerDtoV2, IdleFeesStatus, Session, TestCompanyStatus } from '../dto/charger.dto';
import { useNavigate, useParams } from 'react-router-dom';
import { useDomain } from '../../../hooks/useDomain';
import { useAuth } from '../../../hooks/useAuth';
import { ROUTES } from '../../../types/routes.enum';
import InfoIcon from '../../../assets/icons/Info.svg';
import Location from '../../../assets/icons/Location.svg';
import TechnicianIcon from '../../../assets/icons/technicianIcon.svg';
import { useUserPreference } from '../../../hooks/useUserPreference';
import { RuleTypes } from '../../../stores/types/price.interface';
import EmergencyStop from '../emergency-stop.component';
import CustomButton from '../../react-native-material-wrapper/button.component';
import { useSelector } from 'react-redux';
import { getTheme } from '../../../stores/selectors/theme.selector';
import HaveQuestions from '../../login/have-questions.component';

const useStyles = makeStyles(() => ({
  customTypography: {
    fontSize: '16px',
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: '24px',
  },
  container: {
    paddingTop: '8px',
  },
  customBox: {
    display: 'flex',
    margin: '40px 0px 48px 0px',
    justifyContent: 'center',
    backgroundColor: '#F2F4F6',
    height: '48px',
    alignItems: 'center',
    cursor: 'pointer',
    borderRadius: '4px',
  },
  text: {
    color: '#4E5968',
    textAlign: 'center',
    fontSize: '16px',
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '24px',
    paddingLeft: '8px',
  },
  technicianContainer: {
    display: 'flex',
    margin: '0px 0px 40px 0px',
    justifyContent: 'center',
    border: '1px solid #D1D6DB',
    borderRadius: '4px',
    height: '48px',
    alignItems: 'center',
    cursor: 'pointer',
  },
  customText: {
    color: '#4E5968',
    textAlign: 'center',
    fontSize: '16px',
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '24px',
    paddingLeft: '8px',
  },
  holdMessageText: {
    fontSize: '14px',
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '20px',
    color: '#4E5968',
  },
  descriptionText: {
    fontSize: '16px',
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '24px',
    color: '#202223',
  },
  troubleLogin: {
    display: 'flex',
    margin: '32px 0px 32px 0px',
    justifyContent: 'center',
    height: '26px',
    alignItems: 'center',
    cursor: 'pointer',
  },
  preAuthWarning: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    paddingTop: '32px',
    paddingBottom: '68px',
  },
  preAuthWarningText: {
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '20px',
    color: '#4E5968'
  }
}));

interface ChargerDescription {
  themeColor: string;
  charger: ChargerDtoV2;
  isLogined: boolean;
  stopCharge: Function;
  isChargingBySelf: boolean;
  activeSession: Session;
}

const ChargerDescriptions = ({
  charger,
  isLogined,
  stopCharge,
  isChargingBySelf,
  activeSession,
}: ChargerDescription) => {
  
  const { i18n } = useTranslation();

  const navigate = useNavigate();
  const { domain } = useDomain();
  const auth = useAuth();
  const currentUserEmail = auth?.user?.attributes?.email;
  const [technicianViewValue, setTechnicianViewValue] = useState('');
  const chargerId = charger?.chargerId;
  const { userIsNotSelectedForPayGo } = useUserPreference();
  const theme = useSelector(getTheme);
  const { chargerName } = useParams();

  useEffect(() => {
    const savedTechnicianViewValue =
      localStorage.getItem(`technicianView_${currentUserEmail}-${chargerId}`) ||
      '';
    setTechnicianViewValue(
      savedTechnicianViewValue === 'true'
        ? i18n.t('technicianViewTurnOffLabel')
        : i18n.t('technicianViewTurnOnLabel'),
    );
  }, []);

  const navigateToTechnicianView = () => {
    navigate(`/app/${domain}/${ROUTES.TECHNICIAN_VIEW}`, {
      state: { chargerId },
    });
  };
  const showTroubleLogin = () => {
    navigate(`/app/${window.domainName}/${ROUTES.LOGIN_INFO}?charger=${chargerName}`);
  };

  const getFullAddress = (charger: ChargerDtoV2) => {
    const { location } = charger;
    let fullAddress = '';
    if (location?.streetAddress) {
      fullAddress += location.streetAddress.concat(', ');
    }
    if (location?.city) {
      fullAddress += location.city.concat(', ');
    }
    if (location?.stateOrRegion) {
      fullAddress += location.stateOrRegion.concat(', ');
    }
    if (location?.isoCountryCode) {
      fullAddress += location.isoCountryCode.concat(', ');
    }
    if (location?.zipOrPostalCode) {
      fullAddress += location.zipOrPostalCode;
    }
    return fullAddress;
  };

  const classes = useStyles();

  // User should be selected for PayGo MVP
  // Charging session is not free
  const showTemporaryHoldMessage = () => {
    return (
      userIsNotSelectedForPayGo === false &&
      charger.priceSchedule.type !== RuleTypes.FREE
    );
  };

  const temporaryHoldMessage = () => {
    return (
      (showTemporaryHoldMessage() || !auth.isLogined) && (
        <>
          <Divider
            style={{
              marginTop: auth.isLogined ? '32px' : '0px',
              marginBottom: '32px',
              background: '#E5E8EB',
            }}
          />
          <div style={{ paddingBottom: !auth.isLogined ? '68px' : '32px' }}>
            <div>
              <Typography className={classes.holdMessageText}>
                {i18n.t('temp_hold_msg_1')}
              </Typography>
            </div>
            <div style={{ paddingTop: '16px' }}>
              <Typography className={classes.holdMessageText}>
                {i18n.t('temp_hold_msg_2')}
              </Typography>
            </div>
          </div>
        </>
      )
    );
  };  

  return (
    <>
      {!charger.userHasAccess && isLogined && (
        <>
        <Grid container style={{ padding: '24px 0px 8px' }}>
          <img src={InfoIcon} alt='info_icon' />
          <Typography
            variant='body1'
            className={classes.customTypography}
            style={{
              paddingLeft: '8px',
              color: '#4E5968',
            }}
          >
            {i18n.t('description')}
          </Typography>
        </Grid>

        <Typography
          variant='body2'
          className={classes.descriptionText}
        >
          {i18n.t('it_looks_dont_have_charger_access')}
        </Typography>
        </>
      )}
      {charger.userHasAccess && (
        <>
          {charger.directions && (
            <Grid container style={{ paddingTop: '24px' }}>
              <img src={InfoIcon} alt='info_icon' />
              <Typography
                variant='body1'
                className={classes.customTypography}
                style={{
                  paddingLeft: '8px',
                  color: '#4E5968',
                }}
              >
                {i18n.t('description')}
              </Typography>
            </Grid>
          )}
          {charger.directions && (
            <Grid container className={classes.container}>
              <Typography
                variant='body1'
                className={classes.customTypography}
                style={{
                  fontWeight: '400',
                }}
              >
                {charger.directions}
              </Typography>
            </Grid>
          )}
          {(charger.testCompany === TestCompanyStatus.NOT_FROM_TEST_COMPANY || !charger.testCompany) &&
            <>
            <Grid
            container
            style={{
              paddingTop: '32px',
              color: '#4E5968',
            }}
          >
            <img src={Location} alt='location_icon' />
            <Typography
              variant='body1'
              className={classes.customTypography}
              style={{
                paddingLeft: '8px',
              }}
            >
              {i18n.t('location')}
            </Typography>
          </Grid>
          <Grid container className={classes.container}>
            <Typography
              variant='body1'
              className={classes.customTypography}
              style={{
                fontWeight: '400',
                color: '#202223'
              }}
            >
              {getFullAddress(charger)}
            </Typography>
          </Grid>
          </>
          }
        </>
      )}
      {activeSession?.idleFeeStatus === IdleFeesStatus.IMPOSED && <Box style={{paddingTop: '24px'}}>
        <EmergencyStop stopCharge={stopCharge} isChargingBySelf={isChargingBySelf} charger={charger}/>
      </Box>}
      {!auth.isLogined &&
      <Box className={classes.troubleLogin}>
        <CustomButton
          size='large'
          style={{
            color: theme.primary,
            fontSize: '14px',
            fontWeight: 500,
            lineHeight: '20px',
            letterSpacing: '0em',
            textAlign: 'center',
            fontFamily: 'Inter',
            textTransform: 'inherit',
            padding: '0px',
          }}
          onClick={showTroubleLogin}
        >
          {i18n.t('trouble_login')}
        </CustomButton>
      </Box>
      }
      {temporaryHoldMessage()}
      {auth.isLogined && 
      <div style={{ paddingBottom: '68px' }}>
        <HaveQuestions chargerDetail={charger}/>
      </div>
      }
      {charger?.maintenanceFlag && auth.isLogined && (
        <Box
          className={classes.technicianContainer}
          onClick={() => navigateToTechnicianView()}
        >
          <img src={TechnicianIcon} alt='technician_icon' />
          <span className={classes.customText}>{technicianViewValue}</span>
        </Box>
      )}
    </>
  );
};

export default ChargerDescriptions;
