import React from 'react';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { authStyles } from './authStyles';
import { useSelector } from 'react-redux';
import { getTheme } from '../../stores/selectors/theme.selector';
import { TestCompanyStatus } from '../charger/dto/charger.dto';

const HaveQuestions = ({chargerDetail}) => {        
  const { i18n } = useTranslation();
  const classes = authStyles();
  const theme = useSelector(getTheme);

  const callSupport = () => {
    window.location.href = `tel:${chargerDetail.contactInfo}`;
  };

  const redirectHelpCenter = () => {
    if (chargerDetail.whiteLabellingName === 'ChargeLab') {
      window.open('https://chargelab.zendesk.com/hc/en-us/sections/25563504258843-Charging-Basics');
    }
    else window.open('https://eatoncharging.zendesk.com/hc/en-ca/sections/27529579005339-How-to-start-a-charging-session');
  };

  return (
    <Box className={classes.footerBox}>
        <Typography
            variant='h3'
            style={{ fontFamily: 'Inter', fontSize: '16px', fontWeight: '600', lineHeight: '24px', textAlign: 'center'}}
          >
            {i18n.t('have_questions')}
          </Typography>
          <Box className={classes.footerDetailsBox}>
          {(chargerDetail.whiteLabellingName === 'ChargeLab' || chargerDetail.whiteLabellingName === 'Eaton') &&
          <>
            <Typography
            variant='h3'
            style={{color: '#4E5968'}}
            className={classes.typographyClass}
          >
            {i18n.t('visit_help')}
          </Typography>
          <Typography
            variant='h3'
            style={{color: theme.navigationSelectedColor, cursor: 'pointer'}}
            className={classes.typographyClass}
            onClick={redirectHelpCenter}
          >
            {i18n.t('help_center')}
          </Typography>
          {(chargerDetail.testCompany === TestCompanyStatus.NOT_FROM_TEST_COMPANY || !chargerDetail.testCompany) &&
            <>
            <Typography
            variant='h3'
            style={{color: '#4E5968'}}
            className={classes.typographyClass}
          >
            {i18n.t('or')}
          </Typography>
          </>
          }
          <Typography
            variant='h3'
            style={{color: theme.navigationSelectedColor, cursor: 'pointer'}}
            className={classes.typographyClass}
            onClick={callSupport}
          >
            {i18n.t('Call_support')}
          </Typography>
          </>
          }
          </Box>
        </Box>
  );
};

export default HaveQuestions;
